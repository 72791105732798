import React from "react"
import "../../components/SeguimientoComponents/seguimiento.css"
import LayoutProvider from "../../components/Layout"
import ReclamosViewPage from '../../components/templates/ReclamosView';

const SeguimientoPage = ({ location }) => {



  return (
    <LayoutProvider title="Sigue Tu Reclamo" location={location}>
      {/* <p className="title-seguimiento-ss">Reclamos</p> */}

      <ReclamosViewPage location={location}/>
 
    </LayoutProvider>
  )
}

export default SeguimientoPage
