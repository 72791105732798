import React, { useEffect} from 'react';
import ReclamosTramitesComponent from "../ReclamosComponents/ReclamosTramites"
import { navigate } from 'gatsby';

const ReclamosViewPage = ({location}) => {

    useEffect(() => {
        
      const logueado = localStorage.getItem("logueado")
      const requiere_actualizar = localStorage.getItem("requiere_actualizar")
  
      if (logueado === false || logueado === null || logueado === undefined) {
          navigate("/")
      } else if (requiere_actualizar === true || requiere_actualizar === "true") {
          navigate("/inicio/editar-perfil/")
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return ( 
            
        <ReclamosTramitesComponent location={location} /> 

     );
}
 
export default ReclamosViewPage;