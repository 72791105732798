import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import TablaSeguimientoComponent from "./Tabla/TablaSeguimiento"
import CustomTable from '../../Utils/CustomTable'
import NoInfoComponent from '../../Utils/NoInfo'
import { FlipToFrontSharp, Visibility } from '@material-ui/icons'
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { 
  GetFolioReclamoF,
  SetLastPage,
  SetLastCant,
  SetLastTotal,
  SetCurrentTramites,
  SetCurrentFiltros,
  SetEntidadesRequeridas
} from "../../state/actions/Dashboard/ReclamosTramitesActions"
import { FaSearch } from "react-icons/fa"
import "./tramites.css"
import clienteAxios from '../../Utils/axios'
import { FaArrowCircleLeft } from "react-icons/fa"
//import * as Sentry from '@sentry/gatsby';

const ReclamosTramitesComponent = ({ location }) => {
  const Status = useSelector(state => state.ReclamosTramites.status)
  const lastPage = useSelector(state => state.ReclamosTramites.lastPage)
  const lastCant = useSelector(state => state.ReclamosTramites.lastCant)
  const lastTotal = useSelector(state => state.ReclamosTramites.lastTotal)
  const lastFiltros = useSelector(state => state.ReclamosTramites.currentFiltros)
  const FasesSeguimiento = useSelector(state => state.ReclamosTramites.FasesSeguimiento)
  const EntidadesRequeridas = useSelector(state => state.ReclamosTramites.EntidadesRequeridas)
  const dispatch = useDispatch()
  const [rows, setRows] = useState([])
  const [progress, setProgress] = useState(false)
  const [primeraBusqueda, setPrimeraBusqueda] = useState(true)
  const [total, setTotal] = useState(lastTotal)
  const [page, setPage] = useState(lastPage)
  const [cant, setCant] = useState(lastCant)
  const [filtros, setFiltros] = useState(lastFiltros)
  const [fasesOpts, setFasesOpts] = useState([])
  const [EROpts, setEROpts] = useState([])

  useEffect(() => {
    init()
  }, [])
  
  useEffect(() => {
    if(EntidadesRequeridas.length === 0){
      clienteAxios.get(`IsapresList/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          accept: "application/json",
        },
      })
      .then(res => {
        dispatch(SetEntidadesRequeridas(res.data.lista_isapres))
      })
      .catch( error => {
        // Sentry.captureException(error);
      })
    }else{
      setEROpts(EntidadesRequeridas.map(e => {return e.Nombre_Isapre}))
    }
  }, [EntidadesRequeridas])
  
  useEffect(() => {
    var arr = []
    FasesSeguimiento.forEach(element => {
      if(arr.findIndex(e => e === element.nombre_fase) === -1 ){
        arr.push(element.nombre_fase)
      }
    });
    setFasesOpts(arr)
  }, [FasesSeguimiento])

  const init = () => {
    const folio = localStorage.getItem('folio')
    const intendencia = localStorage.getItem('intendencia')

    if(folio !== null && intendencia !== null){
      localStorage.removeItem('folio')
      localStorage.removeItem('intendencia')
    }
    onChangePage(lastPage, lastCant)
  }
  
  useEffect(() => {
    if(page !== lastPage){
      dispatch(SetLastPage(page))
    }
  }, [page])
  
  useEffect(() => {
    if(cant !== lastCant){
      dispatch(SetLastCant(cant))
    }
  }, [cant])

  useEffect(() => {
    if(total !== lastTotal){
      dispatch(SetLastTotal(total))
    }
  }, [total])

  useEffect(() => {
    dispatch(SetCurrentTramites(rows))
  }, [rows])

  useEffect(() => {
    dispatch(SetCurrentFiltros(filtros))
    if(!primeraBusqueda){
      onChangePage(0, cant)
    }
  }, [filtros])

  const styleAcciones = (content) => {
    return <button
        type="button"
        className="btn btn-notif btn-sm ml-auto my-0 text-light"
        onClick={() =>
          GetClick(content.n_reclamo, content.intendencia, content.tipo)
        }
        key={`ver-${content.n_reclamo}`}
      >
      Ver <FaSearch />
    </button>
  }

  const GetClick = (nreclamo, intendencia, tipo, fase) => {
    const FolioAntecedenteF = () => {
      dispatch(
        GetFolioReclamoF({
          data: { folio: nreclamo, intendencia: intendencia },
        })
      )
    }
    localStorage.setItem("folio", nreclamo)
    localStorage.setItem("intendencia", intendencia)
    localStorage.setItem("tipo", tipo)
    FolioAntecedenteF()
    navigate(`/inicio/tramites/detalle/`)
  }
  
  const headCells = [
    {
      id: 'n_reclamo',
      disablePadding: false,
      alignContent: 'left',
      label: 'Folio'
    },
    { id: 'tramitacion', align: 'left', alignContent: 'left',  label: 'Tipo de Tramitacion' },
    { id: 'fecha_ingreso', align: 'left', alignContent: 'left', label: 'Fecha de Ingreso' },
    { id: 'nombre_aseguradora', align: 'left', alignContent: 'left', label: 'Entidad Requerida' },
    { id: 'fase', align: 'left', alignContent: 'left', label: 'Fase', style: ( content ) => { return content.fase.nombre } },
    { id: 'accion', align: 'center', alignContent: 'center', label: 'Acción', style: ( content ) => { return styleAcciones(content)} }
  ];
  
  const validators = [
    {
      type: 'text-numeric',
      target: 'n_reclamo',
      title: 'Folio',
    },
    {
      type: 'select',
      target: 'tipoTramitacion',
      title: 'Tipo de Tramitacion',
      options: ['Reclamo Isapre/Fonasa', 'Reclamo contra Prestadores de Salud'],
    },
    // {
    //   type: 'dateRange',
    //   target: 'fechaIngreso',
    //   title: 'Fecha Ingreso',
    // },
    {
      type: 'select',
      target: 'entidadRequerida',
      title: 'Entidad Requerida',
      // options: ['Cruz Blanca S.A.', 'Vida Tres S.A.', 'Nueva Masvida S.A.', 'Banmédica S.A.'],
      options: EROpts.sort(),
    },
    {
      type: 'select',
      target: 'fase',
      title: 'Fase',
      // options: ['Admisibilidad ', 'Archivado'],
      options: fasesOpts.sort(),
    },
  ];

  const onChangePage = async (newPage, rowsPerPage) => {
    setProgress(true)

    const tokenaccess = localStorage.getItem("token")
    const rutsession = localStorage.getItem("rut")
    const dvsession = localStorage.getItem("dv")

    const dataForm = new FormData()
    dataForm.append("run", rutsession)
    dataForm.append("dv", dvsession)

    var filtro = ''
    filtros.forEach(item => {
      if (item.value) {
        if (item.name === 'n_reclamo') {
          filtro += `&s_folio=${item.value}`
        }
        if (item.name === 'tipoTramitacion') {
          filtro += `&s_tramitacion=${item.value}`
        }
        if (item.name === 'entidadRequerida') {
          filtro += `&s_nombre_aseguradora=${item.value}`
        }
        if (item.name === 'fase') {
          filtro += `&s_fase=${item.value}`
        }
      }
    });

    await clienteAxios.post(`ReclamosTramitesP/?page=${newPage + 1}&q=${rowsPerPage}${filtro}`, dataForm, {
      headers: {
        Authorization: "Bearer " + tokenaccess,
        accept: "application/json",
      },
    })
      .then(res => {
        setRows(res.data.reclamo_prestador)
        setTotal(res.data.total)
      })
      .catch(error => {
        // Sentry.captureException(error);
      })
    setPrimeraBusqueda(false)
    setProgress(false)
  }

  const changeFilters = (filters) => {
    setFiltros(filters)
  }

  return (
    <>
      <div className="contenedor">
      {/* {Status === 200 ? ( */}
      {/* { ( (primeraBusqueda && total===0) || total>0 ) ? */}
          <CustomTable
            title={'Aquí podrás encontrar todos tus trámites realizados durante el último período'}
            headCells={headCells}
            rows={rows}
            validation={validators}
            validationHandler={changeFilters}
            search={false}
            // actions={actions}
            // handleAction={handleAction}
            denseOption={false}
            totalRows={total}
            onChangePage={onChangePage}
            progress={progress}
            page={page}
            setPage={setPage}
            rowsPerPage={cant}
            setRowsPerPage={setCant}
            firstCharge={primeraBusqueda}
            forzarFiltro={lastFiltros}
          />
        {/* :
        <div className="dashboard-option-ss">
          <div className="option-principal-ss option-certificados" >
            <div className="d-flex" onClick={() => navigate("/inicio/")}>
              <div>
                <FaArrowCircleLeft
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#0073ba",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Sin información
                </p>
                <small style={{ display: "block" }}>
                  Usted no posee información asociada a este módulo. Click para volver al menu principal.
                </small>
              </div>
            </div>
          </div>
        </div>
      } */}
        
      {/* ) : (
        
        <div className="contenedor">
          <NoInfoComponent/>
          </div>
      )} */}
      </div>
    </>
  )
}

export default ReclamosTramitesComponent