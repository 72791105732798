import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Box, Button, Chip, Menu, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import toolbarStyle from './style';
import Search from '../search';

const invertirDateFormat = date => {
  var dateParts = date.toString().split('-');
  return `${dateParts[2]}-${dateParts[1] < 10 ? '0' + dateParts[1] : dateParts[1]}-${dateParts[0]}`;
};

const CustomTableToolbar = props => {
  const classes = toolbarStyle();
  const { 
    numSelected, 
    title, 
    validation, 
    validationHandler, 
    search, 
    handleSearch, 
    handleValidation, 
    forceReload, 
    add, 
    addText,
    forzarFiltro = null
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterList, setFilterList] = React.useState([]);
  const [content, setContent] = React.useState(null);
  const [reload, setReload] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [forzado, setForzado] = React.useState(false);

  const initFilterList = () => {
    setFilterList(
      validation.map((item, index) => ({
        index: index,
        type: item.type,
        target: item.target,
        value: null,
      })),
    );
  }

  React.useEffect(() => {
    if(filterList.length === 0){
      initFilterList()
    }
  }, [validation]);

  React.useEffect(() => {
    const filterChange = (index, event, aux) => {
      var auxItem = filterList[index];
      var auxArray = filterList;
      switch (auxItem.type) {
        case 'text':
          auxItem.value = event.target.value;
          if (event.target.value === '') {
            auxItem.value = null;
          }
          auxArray[index] = auxItem;
          setFilterList(auxArray);
          handleValidation(auxArray);
          // forceReload();
          setReload(!reload);
          break;
        case 'text-numeric':
          if( !/^\d+$/gm.test(event.target.value) && event.target.value !== ''){
            return
          }
          auxItem.value = event.target.value;
          if (event.target.value === '') {
            auxItem.value = null;
          }
          auxArray[index] = auxItem;
          setFilterList(auxArray);
          handleValidation(auxArray);
          // forceReload();
          setReload(!reload);
          break;
        case 'select':
          auxItem.value = event.target.value;
          if (event.target.value === 'all') {
            auxItem.value = null;
          }
          auxArray[index] = auxItem;
          setFilterList(auxArray);
          handleValidation(auxArray);
          // forceReload();
          setReload(!reload);
          break;
        case 'select_list':
          auxItem.value = event.target.value;
          if (event.target.value === 'all') {
            auxItem.value = null;
          }
          auxArray[index] = auxItem;
          setFilterList(auxArray);
          handleValidation(auxArray);
          // forceReload();
          setReload(!reload);
          break;
        case 'numericRange':
          if (aux === 'min') {
            auxItem.min = event.target.value;
          }
          if (aux === 'max') {
            auxItem.max = event.target.value;
          }
          auxArray[index] = auxItem;
          setFilterList(auxArray);
          handleValidation(auxArray);
          // forceReload();
          setReload(!reload);
          break;
        case 'dateRange':
          if (aux === 'min') {
            auxItem.min = event.target.value;
          }
          if (aux === 'max') {
            auxItem.max = event.target.value;
          }
          auxArray[index] = auxItem;
          setFilterList(auxArray);
          handleValidation(auxArray);
          // forceReload();
          setReload(!reload);
          break;
        default:
          return null;
      }
    };
    
    if(forzarFiltro && forzarFiltro.length>0 && filterList.length>0 && !forzado){
      forzarFiltro.forEach(forzado => {
        const idx = validation.findIndex(val => val.target === forzado.name)
        if(idx >= 0){
          const falseEvent = {
            target: {
              value: forzado.value,
            }
          }
          filterChange(idx, falseEvent)
        }
      });
      setForzado(true)
    }

    const handleEnter = (e) => {
      if(e.key === 'Enter'){
        onFilter()
      }
    }

    setContent(
      filterList.map((filter, filterIndex) => {
        switch (validation[filter.index].type) {
          case 'text':
            return (
              <MenuItem key={`filter-${filterIndex}`}>
                <FormControl key={validation[filter.index].title} className={classes.formControl}>
                  <TextField 
                    id={`demo-simple-select${filterIndex}`} 
                    label={validation[filter.index].title} 
                    variant="standard" 
                    onChange={event => {
                      filterChange(filterIndex, event);
                    }}
                    onKeyDown={handleEnter}
                    value={filter.value ? filter.value : ''}
                  />
                </FormControl>
              </MenuItem>
            );
          case 'text-numeric':
            return (
              <MenuItem key={`filter-${filterIndex}`}>
                <FormControl key={validation[filter.index].title} className={classes.formControl}>
                  <TextField 
                    id={`demo-simple-select${filterIndex}`} 
                    label={validation[filter.index].title} 
                    variant="standard" 
                    onChange={event => {
                      filterChange(filterIndex, event);
                    }}
                    onKeyDown={handleEnter}
                    value={filter.value ? filter.value : ''}
                  />
                </FormControl>
              </MenuItem>
            );
          case 'select':
            return (
              <MenuItem key={`filter-${filterIndex}`}>
                <FormControl key={validation[filter.index].title} className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label"> {validation[filter.index].title} </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label={validation[filter.index].title}
                    id={`demo-simple-select${filterIndex}`}
                    value={filter.value ? filter.value : 'all'}
                    onChange={event => {
                      filterChange(filterIndex, event);
                    }}>
                    <MenuItem value={'all'} key={`${validation[filter.index].title}-all`}>
                      &nbsp;Todos&nbsp;
                    </MenuItem>
                    {validation[filter.index].options.map((item, index) => (
                      <MenuItem value={item} key={`${validation[filter.index].title}-${item}`}>
                        &nbsp;{item}&nbsp;
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MenuItem>
            );
          case 'select_list':
            return (
              <MenuItem key={`filter-${filterIndex}`}>
                <FormControl key={validation[filter.index].title} className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label"> {validation[filter.index].title} </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label={validation[filter.index].title}
                    id={`demo-simple-select${filterIndex}`}
                    value={filter.value ? filter.value : 'all'}
                    onChange={event => {
                      filterChange(filterIndex, event);
                    }}>
                    <MenuItem value={'all'} key={`${validation[filter.index].title}-all`}>
                      Todos
                    </MenuItem>
                    {validation[filter.index].options.map((item, index) => (
                      <MenuItem value={item} key={`${validation[filter.index].title}-${item}`}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MenuItem>
            );
          case 'numericRange':
            return (
              <MenuItem key={`filter-${filterIndex}`}>
                <TextField
                  type="number"
                  label={`${validation[filter.index].title} Min.`}
                  className={classes.rangeInput}
                  onChange={event => {
                    filterChange(filterIndex, event, 'min');
                  }}
                  value={filter.min || ''}
                  onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                />
                <TextField
                  type="number"
                  label={`${validation[filter.index].title} Max.`}
                  className={classes.rangeInput}
                  onChange={event => {
                    filterChange(filterIndex, event, 'max');
                  }}
                  value={filter.max || ''}
                  onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                />
              </MenuItem>
            );
          case 'dateRange':
            return (
              <MenuItem key={`filter-${filterIndex}`}>
                <TextField
                  label={`${validation[filter.index].title} Min.`}
                  type="date"
                  className={classes.rangeInput}
                  onChange={event => {
                    filterChange(filterIndex, event, 'min');
                  }}
                  value={filter.min || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{width: '48%', marginRight: '4%'}}
                />
                <TextField
                  label={`${validation[filter.index].title} Max.`}
                  type="date"
                  className={classes.rangeInput}
                  onChange={event => {
                    filterChange(filterIndex, event, 'max');
                  }}
                  value={filter.max || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{width: '48%'}}
                />
              </MenuItem>
            );
          default:
            return null;
        }
      }),
    );
  }, [reload, filterList, classes, validation, handleValidation, forzarFiltro]);

  const onSearchChipDelete = () => {
    setSearchTerm('');
    handleSearch('');
    setReload(!reload);
  };

  const handleChangeSearch = event => {
    setSearchTerm(event.target.value);
    handleSearch(event.target.value);
  };

  const onChipDelete = index => {
    var auxItem = filterList[Number(index)];
    if (auxItem.type === 'text') {
      auxItem.value = null;
    }
    if (auxItem.type === 'text-numeric') {
      auxItem.value = null;
    }
    if (auxItem.type === 'select') {
      auxItem.value = null;
    }
    if (auxItem.type === 'select_list') {
      auxItem.value = null;
    }
    if (auxItem.type === 'numericRange') {
      auxItem.min = '';
      auxItem.max = '';
    }
    if (auxItem.type === 'dateRange') {
      auxItem.min = '';
      auxItem.max = '';
    }
    let auxArray = filterList;
    auxArray[Number(index)] = auxItem;
    setFilterList(auxArray);
    handleValidation(auxArray);
    forceReload();
    setReload(!reload);
    onFilter()
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const auxAdd = () => {
    if (add) {
      add();
    }
  };

  const chipFiltro = (key, label, onDelete) => {
    return <Chip 
      key={`chip-${key}`}
      label={label}
      size="small"
      onDelete={onDelete}
      style={{
        marginLeft: '8px'
      }}
    />
  }
  
  const onFilter = () => {
    var filtros = []
    filterList.forEach(item => {
      if( item.type === "dateRange" ){
        if( item.min || item.max ){
          filtros.push({ name: item.target, min: item.min, max: item.max })
        }
      }else{
        if( item.value ){
          filtros.push({ name: item.target, value: item.value })
        }
      }
    })
    validationHandler(filtros)
    handleClose()
  } 
  
  const onClear = () => {
    validationHandler([])
    initFilterList()
  } 

  return (
    <>
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} Seleccionados
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}

          {add ? (
            <Button color="primary" onClick={auxAdd}>
              {addText ? addText : 'Agregar'}
            </Button>
          ) : null}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : validation.length > 0 || search ? (
        <Fragment>
          {search ? (
            <Fragment>
              <Search iconPosition="right" onChange={handleChangeSearch} value={searchTerm} border={false} onlyIcon />
              <div className={classes.chipsRoot}>
                {searchTerm && <Chip label={searchTerm} onDelete={onSearchChipDelete} />}
              </div>
            </Fragment>
          ) : null}

          {validation.length > 0 ? (
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={handleClick}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Fragment />
          )}

          <Menu
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {content}

            <Box sx={{width: '100%', display: 'flex', justifyContent:'start' }} >
              <Button 
                style={{ marginLeft: '1rem' }} 
                variant="contained" 
                color="primary" 
                onClick={onFilter} 
              >
                Filtrar
              </Button>
              <Button 
                style={{ marginLeft: '1rem' }} 
                onClick={onClear} 
              >
                Limpiar
              </Button>
            </Box>
          </Menu>
        </Fragment>
      ) : null}
    </Toolbar>
    <div style={{width: '100%', paddingLeft: '1rem', paddingBottom: '.5rem'}} >
      {filterList.map((filter, index) => {
        switch (filter.type) {
          case 'text':
            return filter.value && chipFiltro(index, filter.value, () => onChipDelete(filter.index))
          case 'text-numeric':
            return filter.value && chipFiltro(index, filter.value, () => onChipDelete(filter.index))
          case 'select':
            return filter.value && chipFiltro(index, filter.value, () => onChipDelete(filter.index))
          case 'select_list':
            return filter.value && chipFiltro(index, filter.value, () => onChipDelete(filter.index))
          case 'numericRange':
            if (filter.min && filter.max) {
              return chipFiltro(index, `${filter.min} - ${filter.max}`, () => onChipDelete(filter.index))
            } else {
              if (filter.min) {
                return chipFiltro(index, `${filter.min}`, () => onChipDelete(filter.index))
              }
              if (filter.min) {
                return chipFiltro(index, `${filter.max}`, () => onChipDelete(filter.index))
              }
            }
            return null;
          case 'dateRange':
            if (filter.min && filter.max) {
              return chipFiltro(index, `${invertirDateFormat(filter.min)}  -  ${invertirDateFormat(filter.max)}`, () => onChipDelete(filter.index))
            } else {
              if (filter.min) {
                return chipFiltro(index, `${invertirDateFormat(filter.min)}`, () => onChipDelete(filter.index))
              }
              if (filter.min) {
                return chipFiltro(index, `${invertirDateFormat(filter.max)}`, () => onChipDelete(filter.index))
              }
            }
            return null;
          default:
            return null;
        }
      })}
    </div>
    </>
  );
};

export default CustomTableToolbar;

CustomTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
