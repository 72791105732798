import React, { Fragment, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Box, LinearProgress } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import tableStyle from './table.style';
import DropdownMenu from './dropdownMenu';
import EnhancedTableHead from './customTableHead';
import EnhancedTableToolbar from './customTableToolbar';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const convertDateDMY = date => {
  // convierte fecha en formato "dd-mm-yyyy" a Date
  var dateParts = date.toString().split('-');
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
};

const convertDateYMD = date => {
  // convierte fecha en formato "yyyy-mm-dd" a Date
  var dateParts = date.toString().split('-');
  return new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
};

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export default function CustomTable(props) {
  const {
    headCells = [],
    rows = [],
    check = false,
    checkField = '',
    selectedList = () => {},
    title = '',
    validation = [],
    validationHandler = () => {},
    search = false,
    actions = undefined,
    handleAction = undefined,
    add = null,
    addText = '',
    denseOption = true,
    totalRows = 1,
    onChangePage = () => {},
    progress = false,
    rowsPerPage = 10,
    setRowsPerPage = () => {},
    page = 0,
    setPage = () => {},
    firstCharge = false,
    forzarFiltro = null
  } = props;

  const classes = tableStyle();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentSearch, setCurrentSearch] = React.useState('');
  const [currentValidation, setCurrentValidation] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [content, setContent] = React.useState(null);
  const [rowCount, setRowCount] = React.useState(0);
  // const debouncedSearchTerm = useDebounce(currentSearch, 500);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n[headCells[0].id]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage, rowsPerPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    onChangePage(0, event.target.value)
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const forceReload = () => {
    setReload(!reload);
  };

  // React.useEffect(() => {
  //   if (
  //     rows !== null &&
  //     page !== null &&
  //     rowsPerPage !== null &&
  //     rows !== undefined &&
  //     page !== undefined &&
  //     rowsPerPage !== undefined
  //   ) {
  //     if (rows.length && page > Math.ceil(rows.length / rowsPerPage) - 1) {
  //       setPage(page - 1);
  //     }
  //   }
  // }, [rows, page, rowsPerPage]);

  React.useEffect(() => {
    selectedList(selected)
  }, [selected, selectedList]);

  React.useEffect(() => {
    const handleClick = (event, data) => {
      const selectedIndex = selected.indexOf(data);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, data);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    };

    const isSelected = name => selected.indexOf(name) !== -1;

    const filteredRows = () => {
      var auxValidations = currentValidation.filter(v => !!v.value || !!v.min || !!v.max);
      const res = rows.filter((row, index) => {
        var res1 = true;
        var res2 = true;
        if (search && currentSearch) {
          res1 = false;
          headCells.forEach(cell => {
            if (
              row[cell.id] &&
              row[cell.id]
                .toString()
                .toLowerCase()
                .includes(currentSearch.toLowerCase())
            ) {
              res1 = true;
            }
          });
        }
        if (auxValidations.length > 0) {
          auxValidations.forEach(validation => {
            if (validation.type === 'select') {
              const auxVal = row[validation.target] ? row[validation.target].toString().toLowerCase() : ''
              if (validation.value.toLowerCase() === auxVal) {
                res2 = res2 && true;
              } else {
                res2 = res2 && false;
              }
            }

            if (validation.type === 'select_list') {
              if (
                row[validation.target]
                  .map(item => {
                    return item.toLowerCase();
                  })
                  .includes(validation.value.toLowerCase())
              ) {
                res2 = res2 && true;
              } else {
                res2 = res2 && false;
              }
            }

            if (validation.type === 'numericRange') {
              if (
                validation.min &&
                validation.max &&
                Number(validation.min) <= Number(row[validation.target]) &&
                Number(row[validation.target]) <= Number(validation.max)
              ) {
                res2 = res2 && true;
              } else if (!validation.max && validation.min && Number(validation.min) <= Number(row[validation.target])) {
                res2 = res2 && true;
              } else if (!validation.min && validation.max && Number(row[validation.target]) <= Number(validation.max)) {
                res2 = res2 && true;
              } else {
                res2 = res2 && false;
              }
            }

            if (validation.type === 'dateRange') {
              if (
                validation.min &&
                validation.max &&
                convertDateYMD(validation.min) <= convertDateDMY(row[validation.target]) &&
                convertDateDMY(row[validation.target]) <= convertDateYMD(validation.max)
              ) {
                res2 = res2 && true;
              } else if (
                !validation.max &&
                validation.min &&
                convertDateYMD(validation.min) <= convertDateDMY(row[validation.target])
              ) {
                res2 = res2 && true;
              } else if (
                !validation.min &&
                validation.max &&
                convertDateDMY(row[validation.target]) <= convertDateYMD(validation.max)
              ) {
                res2 = res2 && true;
              } else {
                res2 = res2 && false;
              }
            }
          });
        }
        return res1 && res2;
      });
      setRowCount(res.length);
      return res;
    };

    setContent(
      // stableSort(filteredRows(), getComparator(order, orderBy))
        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        rows.map((row, index) => {
          const isItemSelected = isSelected(checkField ? row[checkField] : row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={check ? event => handleClick(event, checkField ? row[checkField] : row.id) : () => {}}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={headCells.length > 0 ? row[headCells[0].id] : index}
              selected={isItemSelected}
              sx={{ height : dense ? 37 : 45}}>
              {check && 
                <TableCell padding="checkbox">
                  <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                </TableCell>
              }

              {headCells.map((item, headIndex) => {
                if (headIndex === 0) {
                  return (
                    <TableCell
                      key={headIndex.toString()}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding={row[item.disablePadding] ? 'none' : 'normal'}
                      align={item.align || 'left'} >
                      &nbsp;{item.style && item.style !== undefined ? item.style(row) : row[item.id]}&nbsp;
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={headIndex.toString()} align={item.alignContent ? item.alignContent : 'left'}>
                      &nbsp;{item.style && item.style !== undefined ? item.style(row) : row[item.id]}&nbsp;
                    </TableCell>
                  );
                }
              })}

              {actions !== undefined ? (
                <TableCell align="center" onClick={event => event.stopPropagation()}>
                  <DropdownMenu
                    items={actions}
                    onItemClick={menu => {
                      handleAction(menu, row);
                    }}
                    TriggerComponent={<MoreHoriz />}
                  />
                </TableCell>
              ) : null}
            </TableRow>
          );
        }),
    );
  }, [
    check,
    currentSearch,
    order,
    orderBy,
    page,
    rows,
    rowsPerPage,
    search,
    currentValidation,
    headCells,
    selected,
    actions,
    handleAction,
    reload,
  ]);

  return (
    <Box className={classes.root}>
      
      { progress &&
        <LinearProgress/>
      }
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          validation={validation}
          validationHandler={validationHandler}
          search={search}
          handleSearch={setCurrentSearch}
          handleValidation={setCurrentValidation}
          forceReload={forceReload}
          add={add}
          addText={addText}
          forzarFiltro={forzarFiltro}
        />
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
              
            {rows.length > 0 || firstCharge?
            <>
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowCount}
                headCells={!!headCells ? headCells : []}
                check={check}
                actions={actions}
                />
              <TableBody>
                { content }
              </TableBody>
            </>
            :
              <Box sx={{ width: '100%', display:'flex', justifyContent: 'center', margin: '1rem 0' }}>
                <h5>
                  No se han encontrado reclamos.
                </h5>
              </Box>
            }
          </Table>
        </TableContainer>
        
        {( rows.length > 0  || firstCharge ) &&
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            backIconButtonProps={
              progress ? { disabled: progress } : undefined
            }
            nextIconButtonProps={
              progress ? { disabled: progress } : undefined
            }
            // labelRowsPerPage={
            //   <Fragment>
            //     {denseOption &&
            //       <Fragment>
            //         &nbsp;Contenido Denso &nbsp;
            //         <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="" />
            //       </Fragment>
            //     }
            //     &nbsp;Registros por página:
            //   </Fragment>
            // }
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"Cantidad"}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />
        }
      </Paper>
    </Box>
  );
}